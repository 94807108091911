import React from 'react';
import logo from './logo.svg';
import PhaseContainer from './components/phaseContainer';
import styled from 'styled-components';
// import './App.css';

const AppStyle = styled.div`
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`

function App() {
  return (
    <AppStyle>
      <div className="App">
        <h3>
          Someone needs to get their license
        </h3>
        <PhaseContainer/>
      </div>
    </AppStyle>
  );
}

export default App;
