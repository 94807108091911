import React from 'react';
import Phase from './phase';
import styled from 'styled-components';

const PhaseWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
`
const phaseData = {
  phaseOne: {
    phase: 'Phase One',
    description: 'Knowledge Test',
    licenseGrade: 'G1',
    vector: '003-checklist.png',
  },
  phaseTwo: {
    phase: 'Phase Two',
    description: 'Road Test Booked',
    licenseGrade: null,
    vector: '002-calendar.png',
  },
  phaseThree: {
    phase: 'Phase Three',
    description: 'Road Test',
    licenseGrade: 'G2',
    vector: '001-car.png',
  },
  phaseFour: {
    phase: 'Phase Four',
    description: 'Full Ontario License',
    licenseGrade: 'G',
    vector: '004-police-car.png',
  },
}

function PhaseContainer() {
  return (
    <PhaseWrapper>
      <Phase phaseData={phaseData.phaseOne}/>
      <Phase phaseData={phaseData.phaseTwo}/>
      <Phase phaseData={phaseData.phaseThree}/>
      <Phase phaseData={phaseData.phaseFour}/>
    </PhaseWrapper>
  )
}

export default PhaseContainer;
