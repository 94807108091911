import React from 'react';
import styled from 'styled-components';
import PhaseInfoPanel from './phaseInfoPanel';

const PhaseStyle = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  justify-content: center;
  padding: 10%;
  margin: 5%;
  border-radius: 20px;
  /* height: 20%;
  width: 20%; */
  img {
    align-self: center; 
  }
  h3 {
    color: yellow;
  }
  #license-grade {
    background: black;
  }
  header {
    width: 100%;
    display: block;
  } 
`

const PhaseInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border: 3px solid white;
  border-radius: 20px;
  &:hover {
    /* opacity: .5; 
    -webkit-filter: grayscale(100%) sepia(100%); */
    border: 3px solid pink;
  }
  img {
    align-self: center; 
  }
  #license-grade {
    background: black;
  } 
`

function Phase(props) {

  return (
    <PhaseStyle>
      <h4>{props.phaseData.phase.toUpperCase()}</h4>
        <PhaseInfo>
          <img id="vector" src={`./${props.phaseData.vector}`} alt={props.phaseData.vector} />
          <p id="phase-description">{props.phaseData.description}</p>
          <p id="license-grade">{props.phaseData.licenseGrade}</p>
        </PhaseInfo>
  </PhaseStyle>
  )
}

export default Phase;